import {
    createBrowserRouter,

} from "react-router-dom";
import Homepage from "../Pages/Homepage";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import Terms from "../Pages/Terms";
const routes = createBrowserRouter([
    {
    path:'/',
    element:<Homepage />
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/terms',
      element: <Terms />
    }
])
export {routes}