import React from 'react';
import "../assets/css/Homepage.css";

const PrivacyPolicy = () => {
    return (
        <div className={"meta-text mt-6"} style={{ padding: "50px" , color:'white', lineheight: 1.5, margin: "20px" }}>
            <h1>
                <b> Senderly SMS Privacy Policy</b>
            </h1><h3>
                <em> Personal Information We Collect </em>
            </h3><h4>
                When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”
                </h4><h4>
                <em>We collect device information using the following technologies</em>
                
                </h4><h4>
                - “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
                </h4><h4>
                - “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
                </h4><h4>
                - “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.
                </h4><h4>
                Additionally, when you make a purchase or attempt to purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers, bank information, email address, and phone number. We refer to this information as “Order Information.”
                    </h4><h4>
                When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.
                </h4><h4>
                <em>How do we use your personal information?</em>
                
                </h4><h4>
                We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to:
                </h4><h4>
                Communicate with you;
                </h4><h4>
                Screen our orders for potential risk or fraud; and
                </h4><h4>
                When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.
                </h4><h4>
                We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).
                </h4><h4>
                <em>Sharing your personal information</em>
                </h4><h4>
                We share your Personal Information with third parties to help us use your Personal Information, as described above.
                </h4><h4>
                Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
                </h4><h4>
                As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page.
                </h4><h4>
                Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at: http://optout.aboutads.info/.
                </h4><h4>
                <em>How we store information</em>
                
                </h4><h4>
                Storage and Processing:
                </h4><h4>
                - Your information collected through the Service may be stored and processed in the United States or any other country in which Senderly SMS, its Affiliates or Service Providers maintain facilities.
                </h4><h4>
                - Senderly SMS, its Affiliates, or Service Providers may transfer information that we collect about you, including personal information across borders and from your country or jurisdiction to other countries or jurisdictions around the world. If you are located in the European Union or other regions with laws governing data collection and use that may differ from U.S. law, please note that we may transfer information, including personal information, to a country and jurisdiction that does not have the same data protection laws as your jurisdiction.
                </h4><h4>
                - By registering for and using the Service you consent to the transfer of information to the U.S. or to any other country in which Senderly SMS, its Affiliates or Service Providers maintain facilities and the use and disclosure of information about you as described in this Privacy Policy.
                </h4><h4>
                - We use commercially reasonable safeguards to help keep the information collected through the Service secure and take reasonable steps (such as requesting a unique password) to verify your identity before granting you access to your account. However, we cannot ensure the security of any information you transmit to us or guarantee that information on the Service may not be accessed, disclosed, altered, or destroyed.
                </h4><h4>
                - Please do your part to help us. You are responsible for maintaining the secrecy of your unique password and account information, and for controlling access to emails between you and Senderly SMS, at all times. Your privacy settings may also be affected by changes the social media services you connect to Senderly SMS make to their services. We are not responsible for the functionality, privacy, or security measures of any other organization.
                </h4><h4>
                <em>Do not track</em>
                </h4><h4>
                Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.
                </h4><h4>
                <em>Your rights</em>
                </h4><h4>
                If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. You can email getsenderly@gmail.com to request your data be deleted. If you would like to exercise this right, please contact us through the contact information below.
                </h4><h4>
                Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.
                </h4><h4>
                <em>Data retention</em>
                </h4><h4>
                When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.
                </h4><h4>
                <em>Minors</em>
                </h4><h4>
                The Site is not intended for individuals under the age of 18.
                </h4><h4>
                <em>Changes</em>
                </h4><h4>
                We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
                </h4><h4>


                <em>Contact us</em>
                </h4><h4>
                For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at getsenderly@gmail.com or by mail using the details provided below:
                </h4><h4>
                3870 Oak Lane Marietta, Georgia 30062

            </h4>
        </div>
    );
};

export default PrivacyPolicy;
