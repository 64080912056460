import React from 'react';
import "../assets/css/Homepage.css";
const Terms = () => {
    return (
        <div className={"meta-text mt-6"} style={{ padding: "50px" , color:'white', lineheight: 1.5, margin: "20px" }}>
          
            <ui-title-bar title="Terms and Conditions"></ui-title-bar>
            <h4 as='h1' variant='headingLg'>Senderly SMS Terms of Service</h4>
            <br/>
            <h4 as='p' variant='bodyLg'>
            Senderly SMS Inc. (“Senderly SMS”) provides text-based services to individuals and
commercial entities to assist in purchasing goods and services from third parties selected by
such individuals and commercial entities (“Service”). Senderly SMS owns and operates the
internet site senderly-ai.com (together with the Service and Software, the “Site”) and provides
the information and services on the Site to website visitors and users of the Service conditioned
upon acceptance, without modification, of these Terms of Service (“Terms of Service”). By using
the Site, you agree to be bound by these terms and conditions. Senderly SMS reserves the
right, at Senderly SMS’s sole discretion, to change, modify, add, or remove portions of these
Terms of Service periodically. Such modifications shall be effective immediately upon posting of
the modified agreement to the website unless provided otherwise. Continued use of the Site
following the posting of changes to these Terms of Service will be deemed acceptance of those
changes. Use of the Site constitutes full acceptance of, and agreement to the Terms of Service;
if you do not accept the Terms of Service, no rights are granted to use the Site as defined
herein, and you should refrain from accessing the Site. Senderly SMS reserves the right at any
time and from time to time to modify or discontinue, temporarily or permanently, the Site.
            </h4>
            <br/>
            <div style={{marginLeft: '1rem'}}>
                <h4 as='p' variant='bodyLg'>
                1. In order to use the Service Users must provide their first name, last name, email
address, and either or both of banking or credit card information (“Personal Data”) and
create an account. To request deletion of Personal Data, email getsenderly@gmail.com.
You understand that Senderly SMS may share Personal Data with the third parties with
whom you wish to purchase their products or services. You agree to maintain the
security of your account and use best efforts to ensure that no third party accesses your
credentials to access the site and shall report any lost or stolen passwords to Senderly
SMS immediately.
                    </h4>
                    <br/>

                   <h4 as='p' variant='bodyLg'>
                   2. IP Rights Senderly SMS owns the software that enables the online provision of the
Service (“Software”). Senderly SMS shall have the right to aggregate and anonymize
Personal Data (in accordance with applicable law) and use such resulting data set for its
purposes. You shall not reproduce, translate or reverse engineer material from the Site
or sell, rent, or sub-license material or products from the Site. You shall not (a) use, copy,
modify, create a derivative work of, reverse engineer, decompile, or otherwise attempt to
extract the source code of the software underlying the Service or any part thereof, unless
expressly permitted or required by law, and in any case, without providing prior written
notice to Senderly SMS and removing any copyright, trademark, or other proprietary
rights notices contained in or on the Service; and (b) bypass the measures Senderly
SMS may use to prevent or restrict access to the Service, including, without limitation,
features that prevent or restrict use or copying of any content or enforce limitations on
use of the Service or the content therein. You may provide Senderly SMS Feedback
regarding the Service. You assign and agree to assign to Senderly SMS all rights, title
and interest worldwide in and to any Feedback and the related intellectual property rights
embodied therein and agrees to assist Senderly SMS in perfecting and enforcing such
rights. Feedback means all ideas, feedback, suggestions, requests, questions,
comments regarding the Service including without limitation any defects, errors or other
issues you may report about the Service.
                    </h4> 
                    <br/>

                    <h4 as='p' variant='bodyLg'>
                    3. Warranties You represent and warrant that it is authorized to execute this Agreement
and that they are at least the age of majority in their relevant jurisdiction. THE
SOFTWARE AND SERVICE ARE PROVIDED ON AN ‘AS IS’ BASIS AND SENDERLY
SMS ASSUMES NO RESPONSIBILITY FOR THE TIMELINESS, DELETION, OR
FAILURE TO STORE ANY OF YOUR DATA. EACH USER WILL BE SOLELY
RESPONSIBLE FOR ANY DAMAGE TO HIS OR HER COMPUTER SYSTEM OR LOSS
OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY PORTION OF THE SITE.
SENDERLY SMS EXPRESSLY DISCLAIMS ALL WARRANTIES, REPRESENTATIONS
AND INDEMNITIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING,
BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
</h4>
<br/>

        <h4 as='p' variant='bodyLg'>
        4. Limitation of liability THE PARTIES AGREE THE MAXIMUM LIABILITY OF SENDERLY
SMS FOR ANY LIABILITY ARISING OUT OF THIS AGREEMENT SHALL BE LIMITED
TO THE GREATER OF (A) THE AMOUNTS RECEIVED BY SENDERLY SMS IN
CONNECTION WITH PROVIDING THE SERVICES TO YOU DURING THE TWELVE
(12) MONTHS PRIOR TO THE EVENT GIVING RISE TO THE CLAIM AND (B) ONE
THOUSAND DOLLARS ($1,000).
</h4>
<br/>

<h4 as='p' variant='bodyLg'>
5. Governing Law This Agreement shall be governed by the laws of the State of California
without regard to its conflict of laws provisions. The Parties agree to the sole and
exclusive jurisdiction of the state and federal courts located in the City and County of
San Francisco, California. The Parties acknowledge that any breach of this Agreement
will cause irreparable harm for which damages would not be an adequate remedy, and,
therefore, the aggrieved Party will be entitled to injunctive relief with respect thereto in
addition to any other remedies and without any requirement to post bond.
</h4>
<br/>

<h4 as='p' variant='bodyLg'>
6. Trial THE PARTIES AGREE TO WAIVE THE RIGHT TO A TRIAL BY JURY. If the
Parties cannot resolve any dispute within sixty (60) days, the Parties agree to binding
individual arbitration before the American Arbitration Association (“AAA”) under the
Federal Arbitration Act (“FAA”), and not to sue in court; provided if you are an individual,
he or she may initiate a claim in a small claims court of competent jurisdiction. Instead, a
neutral arbitrator will decide and the arbitrator’s decision will be final except for a limited
right of appeal under the FAA. Class action lawsuits, class-wide arbitrations, private
attorney-general actions, and any other proceeding where someone acts in a
representative capacity aren’t allowed (to the extent permitted by applicable law). The
AAA will conduct any arbitration under its Commercial Arbitration Rules.
</h4>
<br/>

<h4 as='p' variant='bodyLg'>
7. Indemnify You shall indemnify, defend and hold Senderly SMS, its affiliates and their
employees, officers, directors, contractors and agents harmless against any cost, loss,
damage, expense or liability arising out of or in connection with a claim, suit, action or
proceeding made or brought by or on behalf of a third party to whom the Service is, or is
to be, provided under this Agreement.
</h4>
<br/>

<h4 as='p' variant='bodyLg'>
8. Severability If a court of competent jurisdiction determines any provision of this
Agreement to be invalid, the Parties nevertheless agree that the court should endeavor
to give effect to the Parties’ intentions as reflected in the provision, and the Parties
intend for the remainder of the Agreement to be enforced and in full effect. Force
Majeure. Senderly SMS shall not be in default or be liable for any delay, failure in
performance or interruption of service resulting directly or indirectly from any cause
beyond its reasonable control. Survival. The following Sections: 2, 3, 4, 5, 6, 7, 8, 10 and
12 shall continue in effect after expiration or termination of this Agreement.
</h4>
<br/>

<h4 as='p' variant='bodyLg'>
9. Termination This Agreement shall terminate upon thirty (30) days notice from either
Party. You shall not use the Services or the Software after the expiration or termination of
this Agreement. Senderly SMS reserves the right to share or transfer personally
identifiable information and aggregate information to a third party should Senderly SMS
ever file for bankruptcy or in the event of a sale, merger or acquisition of Senderly SMS.
</h4>
<br/>
<h4 as='p' variant='bodyLg'>
10. Additional Provisions A waiver by either Party to any term or condition under this
Agreement or any breach thereof, shall not act as a waiver to any such term or condition
or subsequent breach thereof. Affiliate means any entity controlled by, controlling or
under common control with a Party. Notices to Senderly SMS must be provided in writing
to the following address and recipient: getsenderly@gmail.com. This Agreement
contains the entire understanding of the parties on the subject matter hereof. Except as
expressly set forth herein, any amendments to this Agreement must be in writing and
signed by both parties. This Agreement is between you and Senderly SMS; there are no
third-party beneficiaries. Neither this Agreement nor any right, obligation, or remedy
hereunder is assignable, transferable, delegable, or sublicensable by you (including by
operation of law) except with Senderly SMS’s prior written consent, and any attempted
assignment, transfer, delegation, or sublicense shall be null and void. For the avoidance
of doubt, a change of control shall constitute a prohibited assignment
</h4>

            </div>
        </div>
    )
}

export default Terms;
